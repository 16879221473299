import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { analytics, logEvent } from "../firebase";

import BuildIcon from "@mui/icons-material/Build";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import React from "react";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import SpeedIcon from "@mui/icons-material/Speed";
import logoWhite from "../assets/logo-white.png";
import lucasImage from "../assets/lucas.png";

const glassPaper = {
  background: "rgba(255, 255, 255, 0.05)",
  backdropFilter: "blur(10px)",
  borderRadius: "16px",
  border: "1px solid rgba(255, 255, 255, 0.1)",
  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
};

function LandingPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleConsultationClick = () => {
    logEvent(analytics, "schedule_consultation_click");
  };

  return (
    <Box
      sx={{
        bgcolor: "background.default",
        color: "text.primary",
        minHeight: "100vh",
        backgroundImage:
          "linear-gradient(45deg, rgba(0,0,0,0.8), rgba(0,0,0,0.5))",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
      }}
    >
      <Container maxWidth="lg">
        <Box sx={{ py: { xs: 6, md: 12 } }}>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={8}>
                  <img
                    src={logoWhite}
                    alt="Logo"
                    style={{ width: "100%", maxWidth: "300px", height: "auto" }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    sx={{ mb: 2, fontSize: { xs: "1.5rem", md: "2rem" } }}
                  >
                    Lucas Frischmann
                  </Typography>
                  <Typography
                    variant="h5"
                    paragraph
                    sx={{
                      mb: 2,
                      color: "text.secondary",
                      fontSize: { xs: "1.25rem", md: "1.5rem" },
                    }}
                  >
                    Technology & Product Leadership Expert
                  </Typography>
                </Grid>
              </Grid>
              <Typography variant="body1" sx={{ mb: 4 }}>
                With over 20 years of experience driving innovation and
                efficiency at Fortune 500 tech companies and startups, I help
                organizations accelerate growth, optimize operations, and
                deliver customer-centric solutions.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: 2,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth={isMobile}
                  href="#contact"
                >
                  Accelerate Your Growth
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  fullWidth={isMobile}
                  href="#expertise"
                >
                  Explore My Expertise
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Avatar
                sx={{
                  width: "100%",
                  height: "auto",
                  maxWidth: { xs: 250, md: 300 },
                }}
                alt="Lucas Frischmann"
                src={lucasImage}
                variant="square"
              />
            </Grid>
          </Grid>

          <Box sx={{ my: 12 }} id="expertise">
            <Typography variant="h3" gutterBottom align="center" sx={{ mb: 8 }}>
              How I Drive Success
            </Typography>
            <Grid container spacing={4}>
              {[
                {
                  title: "Strategic Innovation",
                  description:
                    "Anticipate market trends and develop visionary strategies that drive product growth, user engagement, and revenue.",
                  icon: <RocketLaunchIcon fontSize="large" />,
                },
                {
                  title: "Operational Excellence",
                  description:
                    "Optimize processes, automate workflows, and implement data-driven decision-making to significantly boost productivity and cut costs.",
                  icon: <SpeedIcon fontSize="large" />,
                },
                {
                  title: "Technology Leadership",
                  description:
                    "Lead cross-functional teams to deliver scalable, user-centric solutions that leverage cutting-edge technologies and create competitive advantages.",
                  icon: <BuildIcon fontSize="large" />,
                },
              ].map((service, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <Card sx={{ ...glassPaper, height: "100%" }}>
                    <CardContent>
                      <Box
                        sx={{ display: "flex", alignItems: "center", mb: 2 }}
                      >
                        {service.icon}
                        <Typography
                          variant="h5"
                          component="h3"
                          gutterBottom
                          color="primary"
                          sx={{ ml: 2 }}
                        >
                          {service.title}
                        </Typography>
                      </Box>
                      <Typography
                        variant="body1"
                        sx={{ color: "text.secondary" }}
                      >
                        {service.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box sx={{ my: 12 }}>
            <Typography variant="h3" gutterBottom align="center" sx={{ mb: 8 }}>
              Proven Track Record
            </Typography>
            <Typography
              variant="body1"
              paragraph
              align="center"
              sx={{ color: "text.secondary", maxWidth: 800, mx: "auto" }}
            >
              As a leader at Meta, X, Snap, and in startup environments, I've
              consistently delivered results:
            </Typography>
            <Grid container spacing={2} sx={{ mt: 4 }}>
              {[
                "Reduced app crash rates by 30% and increased user engagement",
                "Pioneered decentralized engineering performance monitoring, increasing efficiency by 27%",
                "Automated 80% of performance regression detection, saving millions in costs",
                "Developed tools reducing product release time by 30%",
                "Managed a $100+ million advertising portfolio, driving a 14% increase in annual marketing spend",
                "Founded and rapidly scaled an innovative marketplace, securing 100+ B2C contracts in 6 months",
              ].map((achievement, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Box sx={{ ...glassPaper, p: 2, height: "100%" }}>
                    <Typography variant="body2">{achievement}</Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box sx={{ my: 12 }} id="contact">
            <Typography variant="h3" gutterBottom align="center" sx={{ mb: 8 }}>
              Ready to Transform Your Business?
            </Typography>
            <Typography
              variant="body1"
              paragraph
              align="center"
              sx={{ color: "text.secondary", maxWidth: 600, mx: "auto", mb: 4 }}
            >
              Let's discuss how my expertise in strategic leadership,
              operational excellence, and technology innovation can accelerate
              your organization's growth and success.
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                href="mailto:lucas@frischmann.io"
                onClick={handleConsultationClick}
              >
                Schedule a Consultation
              </Button>
            </Box>
          </Box>

          <Box sx={{ mt: 12, textAlign: "center" }}>
            <IconButton
              color="primary"
              href="https://www.linkedin.com/in/lucasfrischmann/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedInIcon />
            </IconButton>
            <IconButton color="primary" href="mailto:lucas@frischmann.io">
              <EmailIcon />
            </IconButton>
          </Box>

          <Box sx={{ mt: 4, textAlign: "center" }}>
            <Typography variant="body2" color="text.secondary">
              © {new Date().getFullYear()} Lucas Frischmann. All rights
              reserved.
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default LandingPage;
