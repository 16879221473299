import React, { useEffect } from "react";

import CssBaseline from "@mui/material/CssBaseline";
import LandingPage from "./components/LandingPage";
import { ThemeProvider } from "@mui/material/styles";
import { logPageView } from "./firebase";
import theme from "./theme";

function App() {
  useEffect(() => {
    logPageView();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LandingPage />
    </ThemeProvider>
  );
}

export default App;
