import { getAnalytics, logEvent } from "firebase/analytics";

import ReactGA from "react-ga4";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCLaUjHCBQxSGNSjhHYakWLnb1Ulw8d_ik",
  authDomain: "frischmann-io.firebaseapp.com",
  projectId: "frischmann-io",
  storageBucket: "frischmann-io.appspot.com",
  messagingSenderId: "608333101570",
  appId: "1:608333101570:web:06743406f168b8e6dd851b",
  measurementId: "G-E58THGHPQZ",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize ReactGA
ReactGA.initialize("G-WY0FMZ7DEF");

// Function to log page views
export const logPageView = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  logEvent(analytics, "page_view", { page_path: window.location.pathname });
};

export { app, analytics, logEvent };
